import React from 'react';

const HStack = ({ children, ...props }) => {

//let styles = style; //{justifyContent:`${justify}`,alignItems:`${align}`}

return (
    <div className='gap-4 flex flex-row' {...props}>
    {children}
    </div>
);
};
  
  
  export default HStack;