// src/Head.js
import React from 'react';

const Head = () => {
  return (
    <head>
      <title>My App Title</title>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </head>
  );
};

export default Head;