import logo from './images/logo_final.png';
import appstore from './images/appstore.png';
import moon from './images/moon.png';

import menu_1 from './images/1_menu.png';
import menu_2 from './images/2_menu.png';
import menu_3 from './images/3_menu.png';
import menu_4 from './images/4_menu.png';
import menu_5 from './images/5_menu.png';
import menu_6 from './images/6_menu.png';
import menu_7 from './images/7_menu.png';
import menu_8 from './images/8_menu.png';
import menu_9 from './images/9_menu.png';


import InfiniteCarousel from 'react-leaf-carousel';
import { Parallax, Background } from 'react-parallax';

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import './App.css';
import HStack from './components/HStack';
import VStack from './components/VStack';
//import './fonts.css';

import { colors_fill,colors_text } from './colors';
import rough from 'roughjs';
import React,{useEffect,useRef,useState} from 'react'

import FooterMobile from './components/FooterMobile';
import Footer from './components/Footer';


const CarouselCard = ({img,title,index})=>{

  useEffect(()=>{
    //  let rect = document.getElementById('canvas')
      const node_0 = document.getElementById(`carousel_${index}`)
    
      const rc_0 = rough.svg(node_0);
      //const rc = rough.canvas(rect);
      let rect_0 = rc_0.rectangle(0, 0,200, 200,{
        stroke:'black',
      }); // x, y, width, height
      node_0.appendChild(rect_0);
  
  
    },[])

  return (<div id={`carousel_${index}`} className='unselectable'
  style={{width:200,height:260}}>
    <VStack>
     <div className="family" style={{fontSize:'1.0em'}}>{title}</div>
    

  <img draggable="false"
   alt=""
    src={img}
    unselectable='on'
    style={{maxWidth:200,marginTop:10,maxHeight:200}}
 />

 </VStack>
</div>)
}

const CarouselArrow = ({side}) => {

  return (
    <div style={{position:'relative',top:'150px',display:'flex',justifyContent:'center',alignItems:'center',width:50,height:50}}>
{(side === 'prev') ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
</svg>:<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg>


  }
    </div>
  )
}

function App() {

  const scrollRef = useRef()

  const buttonWidth=100;
  const buttonHeight=35;
  useEffect(()=>{
  //  let rect = document.getElementById('canvas')
    const node_0 = document.getElementById('button_0')
  
    const rc_0 = rough.svg(node_0);
    //const rc = rough.canvas(rect);
    let rect_0 = rc_0.rectangle(0, 0, buttonWidth, buttonHeight,{
      stroke:'black',
    }); // x, y, width, height
    node_0.appendChild(rect_0);

    const node_1 = document.getElementById('button_1')
  
    const rc_1 = rough.svg(node_1);
    //const rc = rough.canvas(rect);
    let rect_1 = rc_0.rectangle(0, 0, buttonWidth, buttonHeight,{
      stroke:'black',
    }); // x, y, width, height
    node_1.appendChild(rect_1);

    const node_2 = document.getElementById('button_2')
  
    const rc_2 = rough.svg(node_2);
    //const rc = rough.canvas(rect);
    let rect_2 = rc_0.rectangle(0, 0, buttonWidth, buttonHeight,{
      stroke:'black',
    }); // x, y, width, height
    node_2.appendChild(rect_2);

    /*
    const node_3 = document.getElementById('content_1')
  
    const rc_3 = rough.svg(node_3);
    //const rc = rough.canvas(rect);
    let rect_3 = rc_3.rectangle(0, 0, 600, 432,{
      stroke:'black',
      roughness:1
    }); // x, y, width, height
    node_3.appendChild(rect_3);
    */
  },[])
  
  return (
    
<div id="home" className="App" style={{}}>
      <header className="App-header background" style={{overflow:'clip',backgroundColor:colors_fill[3]}} >
       
        <img src={logo} className="App-logo" alt="logo" style={{marginTop:20,marginBottom:0}} />
        <HStack style={{display:'flex',justifyContent:'center'}}>
          <a href="https://catnap.rest">
          <div className='button flex' style={{cursor:'pointer',justifyContent:'center',alignItems:'center'}}>
            <div className="family unselectable"  style={{position:'absolute',color:'black',display:'inline-block'}}>Home</div>
          
            <svg id="button_0" style={{width:buttonWidth,height:buttonHeight}} >
          
            </svg>
            </div>
          </a>
          <a href="#about">
          <div className='button flex' style={{cursor:'pointer',justifyContent:'center',alignItems:'center'}}>
          <div className="family unselectable" style={{position:'absolute',color:'black',display:'inline-block'}}>About</div>
        
          <svg id="button_1" style={{width:buttonWidth,height:buttonHeight}} >
        
          </svg>
          </div>
          </a>
          <a href='https://app.youform.com/forms/2ksql5ha' target='_blank'>
          <div className='button flex' style={{cursor:'pointer',justifyContent:'center',alignItems:'center'}}>
          <div className="family unselectable" style={{position:'absolute',color:'black',display:'inline-block'}}>Contact</div>
        
          <svg id="button_2" style={{width:buttonWidth,height:buttonHeight}} >
        
          </svg>
          </div>
          </a>
        </HStack>
        
        <div className='flex' style={{width:'100vw',height:'70vh',justifyContent:'center',alignItems:'center'}}>

          <VStack>
          <div className='hero-family' style={{lineHeight:1,color:'black'}}>Embark on a journey</div>
          <div className='hero-family' style={{color:'black'}}>to a better sleep.</div>
          
          </VStack>
          
        </div>
       </header>

       <div id="about">
       <div style={{paddingTop:40,paddingBottom:100,backgroundColor:colors_text[2]}}>
        <div style={{marginTop:0,display:'flex', justifyContent:'center'}}>
            
            <a
                href="https://apps.apple.com/us/app/catnap/id6630387493"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appstore}
                  alt="apple download"
                  width={120} 
                  height={'auto'}
                  
                />      
              </a>
              </div>
            
              <div className=""style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
               <VStack  style={{paddingLeft:20,paddingRight:20}}>
              <BrowserView>
              <VStack>
                
                <HStack style={{marginTop:40,justifyContent:'space-between',alignItems:'flex-start'}}>
                <div className="mid-family" style={{}}> Why? </div>
                <div  className='max-w-md md:max-w-lg family' style={{marginLeft:20,textAlign:'left',marginTop:0,fontSize:16}}>
              Getting good sleep is important for overall wellness,
              reduced stress, mental acuity, longevity and peace of mind.
              
              </div>
              </HStack>
              <HStack style={{marginTop:50,justifyContent:'space-evenly',alignItems:'flex-start'}}>
              
              <div className="mid-family" style={{position:'relative',top:0}}> What? </div>
              <div  className='max-w-md md:max-w-lg family' style={{textAlign:'left',marginLeft:20,marginTop:0,fontSize:16}}>
              The key to a good nights rest is your daily habits. Catnap is designed around the 10-3-2-1 sleep system which reminds you to fullfill
              the following goals. <br/><br/>
              10 hours before sleep no caffeine.<br/>
              3 hours before sleep no food.<br/>
              2 hours before sleep no liquids.<br/>
              1 hour before sleep no bluelight.<br/><br/>
              Additionally there are sleep factors which you can partake in with your bodydouble Ishii the cat.
              </div>
              </HStack>
              </VStack>
             
              </BrowserView>

              <MobileView>

              <VStack>
                
                <VStack style={{marginTop:40,justifyContent:'center',alignItems:'center'}}>
                <div className="mid-family" style={{marginBottom:20}}> Why? </div>
                <div  className='max-w-md md:max-w-lg family' style={{marginLeft:20,textAlign:'left',marginTop:0,fontSize:16}}>
              Getting good sleep is important for overall wellness,
              reduced stress, mental acuity, longevity and peace of mind.
              
              </div>
              </VStack>
              <VStack style={{marginTop:50,justifyContent:'center',alignItems:'center'}}>
              
              <div className="mid-family" style={{marginBottom:20}}> What? </div>
              <div  className='max-w-md md:max-w-lg family' style={{textAlign:'left',marginLeft:20,marginTop:0,fontSize:16}}>
              The key to a good nights rest is your daily habits. Catnap is designed around the 10-3-2-1 sleep system which reminds you to fullfill
              the following goals. <br/><br/>
              10 hours before sleep no caffeine.<br/>
              3 hours before sleep no food.<br/>
              2 hours before sleep no liquids.<br/>
              1 hour before sleep no bluelight.<br/><br/>
              Additionally there are sleep factors which you can partake in with your bodydouble Ishii the cat.
              </div>
              </VStack>
              </VStack>

              </MobileView>
              
      
              </VStack>
              </div>
          </div>
        </div>
        <div style={{paddingTop:20,height:'60vh',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:colors_fill[3]}}>
        <VStack>
        <div className="mid-family" style={{}}> Sleep Factors </div>
          
        <div style={{overflow:'clip',maxWidth:800,marginTop:20}}>


        <BrowserView>
        <InfiniteCarousel
  style={{overflow:'clip'}}
  wheel={true}
    dots={false}
    autoCycle={true}
    cycleInterval={2000}
    pauseOnHover={false}
    onSwipe={(direction)=>{
      console.log('swipe: ',direction)
    }}
    
    showSides={true}
    sidesOpacity={0.5}
    sideSize={0.25}
    slidesToScroll={1}
    slidesToShow={3}
  >
    <CarouselCard img={menu_1} index={0} title='Aroma Therapy'/>

    <CarouselCard img={menu_2} index={1} title='Sauna'/>
    <CarouselCard img={menu_3} index={2} title='Exercise'/>
    
    <CarouselCard img={menu_4} index={3} title='Melatonin'/>
    <CarouselCard img={menu_5} index={4} title='Magnesium'/>
   
    <CarouselCard img={menu_6} index={5} title='Meditation'/>
    <CarouselCard img={menu_7} index={6} title='ASMR'/>
    <CarouselCard img={menu_8} index={7} title='Nap'/>
    <CarouselCard img={menu_9} index={8} title='Bathing'/>
   
  </InfiniteCarousel>
  </BrowserView>

{
  <MobileView>
<div className="app">
<div className="hs full scrolling-wrapper">
<CarouselCard img={menu_1} index={0} title='Aroma Therapy'/>

<CarouselCard img={menu_2} index={1} title='Sauna'/>
<CarouselCard img={menu_3} index={2} title='Exercise'/>

<CarouselCard img={menu_4} index={3} title='Melatonin'/>
<CarouselCard img={menu_5} index={4} title='Magnesium'/>

<CarouselCard img={menu_6} index={5} title='Meditation'/>
<CarouselCard img={menu_7} index={6} title='ASMR'/>
<CarouselCard img={menu_8} index={7} title='Nap'/>
<CarouselCard img={menu_9} index={8} title='Bathing'/>
</div>
</div>
</MobileView>
  /*

  */
  }
  <div className="family" style={{fontSize:'1em'}}>More factors coming soon...</div>
  </div>
  </VStack>

       </div>
         <BrowserView>
       <div style={{aspectRatio:'1.9',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:colors_text[2]}}>
            <HStack style={{justifyContent:'center',width:'100%',height:'100%'}}>
                <div className="release_1"/>
                <div className="release_2"/>
                <div className="release_3"/>
                 </HStack>
        </div>
        {<Footer/>}
       
        </BrowserView>
        <MobileView>
          <div className="release_1_mobile" style={{height:'70vh',width:'100%',backgroundColor:colors_text[2]}}>
           </div>
           <div className="release_2_mobile" style={{height:'70vh',width:'100%',backgroundColor:colors_fill[3]}}>
           </div>
           <div className="release_3_mobile" style={{height:'70vh',width:'100%',backgroundColor:colors_text[2]}}>
           </div>
            <FooterMobile/>
      
        </MobileView>
    </div>
  );
}
/*

<div style={{paddingTop:20,height:'80vh',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:colors_text[2]}}>
       <VStack>       
          <div className="mid-family" style={{}}>In-App Preview </div>
            <div style={{marginTop:20}}>
              <div>
                  <img src ={release} alt="content" width={600} height={'auto'} />
              </div>
          </div>
        </VStack>
        </div>



<VStack >
                <div className="mid-family" style={{marginTop:40}}> Why? </div>
                <div  className='max-w-md md:max-w-lg family' style={{marginTop:0,fontSize:16}}>
              Getting good sleep is important for overall wellness,
              reduced stress, mental acuity, longevity and peace of mind.

              </div>
              <div className="mid-family" style={{marginTop:50}}> What? </div>
              <div  className='max-w-md md:max-w-lg family' style={{marginTop:0,fontSize:16}}>
              Getting good sleep is important for overall wellness,
              reduced stress, mental acuity, longevity and peace of mind.

              </div>
              </VStack>*/

/*
<div style={{marginTop:40}}>
                <svg id="content_1" style={{position:'absolute',width:700,height:506}} >
        
                </svg>
                <img src ={release} alt="content" width={700} height={'auto'}/>
              </div>*/
/*
<div className="App" style={{}}>
      <header className="App-header background" style={{backgroundColor:'white'}} >
        <img src={logo} className="App-logo" alt="logo" />
        <HStack >
          <div className='button flex' style={{cursor:'pointer',justifyContent:'center',alignItems:'center'}}>
          <div className="family unselectable"  style={{position:'absolute',color:'black',display:'inline-block'}}>Home</div>
        
          <svg id="button_0" style={{width:buttonWidth,height:buttonHeight}} >
        
          </svg>
          </div>
          <div className='button flex' style={{cursor:'pointer',justifyContent:'center',alignItems:'center'}}>
          <div className="family unselectable" style={{position:'absolute',color:'black',display:'inline-block'}}>About</div>
        
          <svg id="button_1" style={{width:buttonWidth,height:buttonHeight}} >
        
          </svg>
          </div>

          <div className='button flex' style={{cursor:'pointer',justifyContent:'center',alignItems:'center'}}>
          <div className="family unselectable" style={{position:'absolute',color:'black',display:'inline-block'}}>Contact</div>
        
          <svg id="button_2" style={{width:buttonWidth,height:buttonHeight}} >
        
          </svg>
          </div>
            
        </HStack>
        <div className='flex' style={{width:'100vw',height:'60vh',justifyContent:'center',alignItems:'center'}}>

          <VStack>
          <div className='hero-family' style={{lineHeight:1,color:'black'}}>Embark on a journey</div>
          <div className='hero-family' style={{color:'black'}}>to a better sleep.</div>
         
          <div style={{color:'black'}}>Install</div>
         
          </VStack>
        </div>
      </header>
    </div>*/
export default App;
