export const colors_fill = [
    '#fec5bb',
    '#fcd5ce',
    '#fae1dd',
    '#f8edeb',
    '#e8e8e4',
    '#d8e2dc',
    '#ece4db',
    '#ffe5d9',
    '#ffd7ba',
    '#fec89a'
    ]
    
    export const colors_text = [
        '#9d8189',
        '#6d6875',
        '#c2cec7'
    ]
    
    export const iso_50 = [
        '#83B0B5',
        '#BCD4D8',
        '#B62717',
        '#F69649',
        '#EEDF84'
    ]