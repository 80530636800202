import React from 'react';

const VStack = ({ children, ...props }) => {

return (
    <div class="flex flex-col" {...props}>
    {children}
    </div>
);
};
  
  
  export default VStack;