import React from 'react';
import HStack from './HStack';
export default function Footer() {
  return (
    <footer 
      className=" text-center text-neutral-600 dark:bg-neutral-600 dark:text-neutral-200 lg:text-left">
    

      {/* <!-- Main container div: holds the entire content of the footer, including four sections (TW Elements, Products, Useful links, and Contact), with responsive styling and appropriate padding/margins. --> */}
      <div className="justify-center flex mx-6 py-10 text-center md:text-right">
        <div style={{maxWidth:1024,width:1024,display:'flex',flexDirection:'row',justifyContent:'space-between'}} className="grid-1  grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {/* <!-- TW Elements section --> */}
          <div style={{maxWidth:200}}  className="justify-center">
            <h6
              className="mb-4 flex items-center justify-center font-semibold uppercase">
              
              <img className='justify-center' src={require('../images/logo_final.png')} style={{width:120,minWidth:120,minHeight:120,height:120}}></img>
             
            </h6>
            <p className="family" style={{textAlign:'center',fontSize:'0.8em'}}>
              Embark on a journey to a better sleep.
            </p>
          </div>
          {/* <!-- Useful links section --> */}
          <HStack style={{justifyContent:'flex-end'}}>
            <div style={{marginRight:80}}>
                <h6
                className="comfortaa-family mt-1 mb-2 flex justify-start font-semibold md:justify-start">
                
                Social Media
                </h6>
                <HStack style={{paddingTop:5,paddingBottom:5,alignItems:'center'}}>
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path
                        d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                    </svg>
                
                    
                    
                    <a href="https://x.com/catnapdotrest" target="_blank" style={{fontSize:'0.9em'}}className="family text-neutral-600 dark:text-neutral-200"
                    >X</a>
                </HStack>
                <HStack style={{paddingTop:5,paddingBottom:5,alignItems:'center'}}>
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path
                        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                    </svg>
                
            
                    <a style={{fontSize:'0.9em'}}className="family text-neutral-400 dark:text-neutral-200"
                    >Facebook</a>
                </HStack>
                

                <HStack style={{paddingTop:5,paddingBottom:5,alignItems:'center'}}>
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path
                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>
                
                    
                    
                    <a style={{fontSize:'0.9em'}}className="family text-neutral-400 dark:text-neutral-200"
                    >Instagram</a>
                </HStack>
          </div>
          {/* <!-- Contact section --> */}
          <div style={{marginRight:40}}>
            <HStack style={{paddingTop:5,paddingBottom:5,alignItems:'center'}}>
            <h6
                className="comfortaa-family flex  font-semibold md:justify-start">
                Contact
                </h6>
                </HStack>
                <HStack style={{paddingTop:5,paddingBottom:5,alignItems:'center'}}>
            
                <p 
                style={{fontSize:'0.9em'}}
                className="family flex items-center justify-center md:justify-start">
                
                Vancouver, BC 10012, CA
                </p>
                </HStack>
                <HStack style={{paddingTop:5,paddingBottom:5,alignItems:'center'}}>
                <a href="mailto:info@catnap.rest">
                <p 
                style={{fontSize:'0.9em'}}
            
                className="family flex items-center justify-center md:justify-start">
                
                info@catnap.rest
                </p>
                </a>
                </HStack>
            </div>
            </HStack>
            </div>
        </div>
        

      {/* <!--Copyright section--> */}
      <div className="bg-neutral-100 p-4 text-center dark:bg-neutral-700">
        <span>© 2024 Copyright </span>
        <a
          className="font-semibold text-neutral-600 dark:text-neutral-400"
          href="https://catnap.rest/"
        >Behest Applications</a>
      </div>
    </footer>
  );
}